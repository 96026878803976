/* GENERATED FILE */
import React, { forwardRef } from "react";

import {
  IconWeight,
  IconProps,
  PaintFunction,
  renderPathForWeight,
} from "../lib";
import IconBase, { RenderFunction } from "../lib/IconBase";

const pathsByWeight = new Map<IconWeight, PaintFunction>();

pathsByWeight.set("bold", (color: string) => (
  <>
    <circle
      cx="128"
      cy="128"
      r="44"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <path
      d="M183.6638,65.08649a84.52233,84.52233,0,0,1,7.2522,7.2521l27.32234,3.90156A103.36981,103.36981,0,0,1,228.40205,100.801L211.84758,122.8728s.20824,6.83942.00065,10.2547l16.561,22.07863a103.36931,103.36931,0,0,1-10.18033,24.554L190.916,183.66144s-4.689,4.98345-7.25071,7.25163l-3.90155,27.32234a103.36971,103.36971,0,0,1-24.56087,10.16371L133.1325,211.84524a84.521,84.521,0,0,1-10.25609.00006l-22.07863,16.561A103.36914,103.36914,0,0,1,76.2438,218.226L72.343,190.91449a84.521,84.521,0,0,1-7.2522-7.25211l-27.32235-3.90155A103.36968,103.36968,0,0,1,27.60479,155.2l16.55447-22.07178s-.20824-6.83942-.00066-10.2547l-16.561-22.07863a103.37,103.37,0,0,1,10.18033-24.554l27.31149-3.90076a84.52233,84.52233,0,0,1,7.2521-7.2522l3.90156-27.32235a103.37019,103.37019,0,0,1,24.56086-10.1637l22.0704,16.55388a84.52281,84.52281,0,0,1,10.25608-.00007l22.07864-16.561A103.37014,103.37014,0,0,1,179.763,37.775Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
  </>
));

pathsByWeight.set("duotone", (color: string) => (
  <>
    <path
      d="M211.8501,133.12744c.20752-3.41528-.00049-10.25464-.00049-10.25464l16.5542-22.07177a103.36316,103.36316,0,0,0-10.16358-24.5608L190.918,72.33862a84.52765,84.52765,0,0,0-7.25195-7.25219L179.76514,37.7749a103.36081,103.36081,0,0,0-24.5542-10.18017l-22.07862,16.561a84.505,84.505,0,0,0-10.25586,0l-22.0708-16.55395A103.37063,103.37063,0,0,0,76.24512,37.76562L72.34375,65.08789a84.46168,84.46168,0,0,0-7.25244,7.2522L37.77979,76.24072a103.37861,103.37861,0,0,0-10.18018,24.5542l16.561,22.07862c-.20752,3.41528.00049,10.25463.00049,10.25463L27.60693,155.2a103.36309,103.36309,0,0,0,10.16358,24.56079l27.32226,3.90161a84.46312,84.46312,0,0,0,7.25244,7.2522l3.9004,27.31152a103.36018,103.36018,0,0,0,24.55419,10.18018l22.07862-16.561a84.50294,84.50294,0,0,0,10.25586,0l22.0708,16.554a103.37032,103.37032,0,0,0,24.56054-10.16382l3.90186-27.32226c2.56152-2.26807,7.25049-7.25171,7.25049-7.25171l27.313-3.90113a103.37883,103.37883,0,0,0,10.18017-24.5542ZM128.00488,176a48,48,0,1,1,48-48A47.99988,47.99988,0,0,1,128.00488,176Z"
      opacity="0.2"
    />
    <circle
      cx="128.00488"
      cy="128"
      r="48"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M183.66575,65.08649a84.52233,84.52233,0,0,1,7.2522,7.2521l27.32235,3.90156A103.37019,103.37019,0,0,1,228.404,100.801L211.84953,122.8728s.20824,6.83942.00066,10.2547l16.561,22.07863a103.37014,103.37014,0,0,1-10.18033,24.554L190.918,183.66144s-4.689,4.98345-7.2507,7.25163l-3.90156,27.32234a103.36981,103.36981,0,0,1-24.56086,10.16371l-22.0704-16.55388a84.521,84.521,0,0,1-10.25609.00006l-22.07863,16.561a103.36931,103.36931,0,0,1-24.554-10.18033L72.345,190.91449a84.521,84.521,0,0,1-7.2522-7.25211l-27.32234-3.90155A103.36971,103.36971,0,0,1,27.60674,155.2l16.55447-22.07178s-.20824-6.83942-.00065-10.2547l-16.561-22.07863a103.36914,103.36914,0,0,1,10.18033-24.554l27.31148-3.90076a84.521,84.521,0,0,1,7.25211-7.2522L76.245,37.76556a103.37009,103.37009,0,0,1,24.56087-10.1637l22.07039,16.55388a84.523,84.523,0,0,1,10.25609-.00007l22.07863-16.561A103.37,103.37,0,0,1,179.765,37.775Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

pathsByWeight.set("fill", () => (
  <>
    <path d="M234.80957,150.40576l-14.87793-19.835c.02246-1.75244.0044-3.62207-.02148-5.11377l14.89257-19.856a8.0008,8.0008,0,0,0,1.32422-6.88476,111.46438,111.46438,0,0,0-10.95068-26.46094,7.99971,7.99971,0,0,0-5.80615-3.93457l-24.48731-3.49707q-1.7981-1.90282-3.70312-3.70361l-3.4961-24.47657a8.00016,8.00016,0,0,0-3.93261-5.80419,111.45413,111.45413,0,0,0-26.45313-10.96729,8.00181,8.00181,0,0,0-6.88818,1.32275L130.62207,36.0376c-1.74414-.04834-3.49316-.04834-5.2373,0l-19.7793-14.83545a8.003,8.003,0,0,0-6.88526-1.32373A111.45813,111.45813,0,0,0,72.25928,30.82861a8.00164,8.00164,0,0,0-3.93506,5.80616L64.82764,61.12158q-1.90211,1.79809-3.70362,3.7041L36.64746,68.32129a7.99959,7.99959,0,0,0-5.8042,3.93213A111.45433,111.45433,0,0,0,19.876,98.70752a7.99838,7.99838,0,0,0,1.32324,6.88769l14.87793,19.835c-.02246,1.75244-.0044,3.62207.02148,5.11377l-14.89258,19.856a8.00086,8.00086,0,0,0-1.32421,6.88477,111.46455,111.46455,0,0,0,10.95068,26.46094,7.99971,7.99971,0,0,0,5.80615,3.93457L61.126,191.17725q1.7981,1.90283,3.70312,3.70361l3.4961,24.47656a8.00016,8.00016,0,0,0,3.93261,5.8042,111.45413,111.45413,0,0,0,26.45313,10.96729,7.9997,7.9997,0,0,0,6.88818-1.32276l19.7876-14.84277c1.74414.04932,3.49316.04932,5.2373,0l19.7793,14.83545a7.99882,7.99882,0,0,0,6.88525,1.32373,111.45806,111.45806,0,0,0,26.46094-10.9502,8.00161,8.00161,0,0,0,3.93506-5.80615l3.50488-24.54541c1.25489-1.22363,2.56348-2.5581,3.60059-3.63135l24.57129-3.50976a7.99959,7.99959,0,0,0,5.8042-3.93213,111.45433,111.45433,0,0,0,10.96728-26.4541A7.9984,7.9984,0,0,0,234.80957,150.40576ZM128.00391,172a44,44,0,1,1,44-44A44.04978,44.04978,0,0,1,128.00391,172Z" />
  </>
));

pathsByWeight.set("light", (color: string) => (
  <>
    <circle
      cx="128.00195"
      cy="128"
      r="48"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M183.66282,65.08649a84.52233,84.52233,0,0,1,7.2522,7.2521l27.32235,3.90156a103.37019,103.37019,0,0,1,10.1637,24.56086L211.8466,122.8728s.20824,6.83942.00066,10.2547l16.561,22.07863a103.37014,103.37014,0,0,1-10.18033,24.554L190.915,183.66144s-4.689,4.98345-7.2507,7.25163l-3.90156,27.32234a103.36981,103.36981,0,0,1-24.56086,10.16371l-22.0704-16.55388a84.521,84.521,0,0,1-10.25609.00006l-22.07863,16.561a103.36931,103.36931,0,0,1-24.554-10.18033l-3.90076-27.31148a84.521,84.521,0,0,1-7.2522-7.25211l-27.32234-3.90155A103.36971,103.36971,0,0,1,27.60381,155.2l16.55447-22.07178s-.20824-6.83942-.00065-10.2547l-16.561-22.07863A103.36914,103.36914,0,0,1,37.777,76.24087l27.31148-3.90076a84.521,84.521,0,0,1,7.25211-7.2522L76.2421,37.76556A103.37009,103.37009,0,0,1,100.803,27.60186l22.07039,16.55388a84.523,84.523,0,0,1,10.25609-.00007l22.07863-16.561a103.37,103.37,0,0,1,24.554,10.18033Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
  </>
));

pathsByWeight.set("thin", (color: string) => (
  <>
    <circle
      cx="128.00098"
      cy="128"
      r="48"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M183.66185,65.08649a84.52221,84.52221,0,0,1,7.25219,7.2521l27.32235,3.90156a103.36936,103.36936,0,0,1,10.1637,24.56086L211.84563,122.8728s.20824,6.83942.00065,10.2547l16.561,22.07863a103.37014,103.37014,0,0,1-10.18033,24.554l-27.31288,3.90133s-4.689,4.98345-7.25071,7.25163l-3.90155,27.32234a103.36988,103.36988,0,0,1-24.56087,10.16371l-22.07039-16.55388a84.521,84.521,0,0,1-10.25609.00006l-22.07863,16.561a103.369,103.369,0,0,1-24.554-10.18033l-3.90077-27.31148a84.52089,84.52089,0,0,1-7.25219-7.25211l-27.32235-3.90155A103.36926,103.36926,0,0,1,27.60284,155.2L44.1573,133.12818s-.20824-6.83942-.00065-10.2547l-16.561-22.07863A103.37,103.37,0,0,1,37.776,76.24087l27.31149-3.90076a84.52089,84.52089,0,0,1,7.2521-7.2522l3.90155-27.32235A103.37026,103.37026,0,0,1,100.802,27.60186l22.07039,16.55388a84.523,84.523,0,0,1,10.25609-.00007l22.07863-16.561a103.36979,103.36979,0,0,1,24.554,10.18033Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
  </>
));

pathsByWeight.set("regular", (color: string) => (
  <>
    <circle
      cx="128"
      cy="128"
      r="48"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M183.66087,65.08649a84.52233,84.52233,0,0,1,7.2522,7.2521l27.32234,3.90156A103.36981,103.36981,0,0,1,228.39912,100.801L211.84465,122.8728s.20824,6.83942.00065,10.2547l16.561,22.07863a103.36931,103.36931,0,0,1-10.18033,24.554l-27.31288,3.90133s-4.689,4.98345-7.25071,7.25163l-3.90155,27.32234A103.36971,103.36971,0,0,1,155.2,228.39912l-22.07039-16.55388a84.521,84.521,0,0,1-10.25609.00006l-22.07863,16.561a103.36914,103.36914,0,0,1-24.554-10.18033l-3.90076-27.31148a84.521,84.521,0,0,1-7.2522-7.25211l-27.32235-3.90155A103.37009,103.37009,0,0,1,27.60186,155.2l16.55447-22.07178s-.20824-6.83942-.00066-10.2547l-16.561-22.07863A103.37,103.37,0,0,1,37.775,76.24087l27.31149-3.90076a84.52233,84.52233,0,0,1,7.2521-7.2522l3.90156-27.32235A103.37019,103.37019,0,0,1,100.801,27.60186l22.0704,16.55388a84.523,84.523,0,0,1,10.25609-.00007l22.07863-16.561a103.37014,103.37014,0,0,1,24.554,10.18033Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

const renderPath: RenderFunction = (weight: IconWeight, color: string) =>
  renderPathForWeight(weight, color, pathsByWeight);

const Gear = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <IconBase ref={ref} {...props} renderPath={renderPath} />
));

Gear.displayName = "Gear";

export default Gear;
